import { USBColumn, USBGrid } from '@usb-shield/react-grid'

import { BreadCrumbInputs } from './breadcrumb.model'
import { BreadcrumbItem } from '@/components/Breadcrumb/breadcrumb.model'
import EDPBreadcrumb from '@/components/EDPBreadcrumb/EDPBreadcrumb'
import styles from '@/components/Breadcrumb/breadcrumb.module.scss'
import { useRouter } from 'next/router'

const Breadcrumb = ({
  items,
  bgEven = false,
  pageTitleMetadata,
  removeMenuFlag,
  removeMenuIndex,
  updateLayoutOpt,
  productVersionNumber,
}: BreadCrumbInputs) => {
  const twelveColsOption = {
    span: null,
    spans: { xlarge: 12, large: 16, medium: 8, small: 4 },
    offset: {
      xlarge: updateLayoutOpt ? 0 : 2,
      large: 0,
      medium: 0,
      small: 0,
    },
    display: 'block',
    padding: 'zero',
    align: 'top',
    justify: 'left',
  },
  homeLink = {
    id: 1,
    text: 'Home',
    href: '/',
  }
  const isCustom = !!items
  items =
    items ?? GetBreadcrumbs(pageTitleMetadata, removeMenuFlag, removeMenuIndex, productVersionNumber)
  let breadcrumbs: BreadcrumbItem[] = [homeLink, ...items]

  return (
    <>
      {breadcrumbs.length > 0 && (
        <USBGrid
          padding={updateLayoutOpt ? `zero` : `normal`}
          columnCount="16"
          addClasses={
            bgEven
              ? `${styles.gridWrapper} ${styles.bgEven}`
              : `${styles.gridWrapper}`
          }
        >
          <USBColumn
            layoutOpts={twelveColsOption}
            addClasses={styles.fullWidth}
          >
            <EDPBreadcrumb
              breadcrumbs={breadcrumbs}
              addClasses={!isCustom ? styles.breadcrumbText : ''}
            ></EDPBreadcrumb>
          </USBColumn>
        </USBGrid>
      )}
    </>
  )
}

export const GetBreadcrumbs = (
  pageTitleMetadata: any,
  removeMenuFlag: any,
  removeMenuIndex: any,
  productVersionNumber: any
) => {
  const router = useRouter()
  let breadcrumbs: BreadcrumbItem[] = []

  if (router && router.asPath) {
    let pathArray: string[] = router.asPath
      .split('?')[0]
      .split('#')[0]
      .split('/')
    // remove the first empty element from the array
    pathArray.shift()
    let href = ''

    // build breadcrumbs
    breadcrumbs = pathArray.map((path, index) => {
      let urlText = path.replace('api', 'API')
      urlText = urlText.replace('faq', 'FAQ')
      urlText =  urlText.replace('portal-access-form', 'Verify organization')
      href += `/${path}`
      return {
        id: index + 2,
        text: decodeURI(urlText.replace(/[-_]/, ' ')),
        href: href,
      }
    })
  } else {
    console.error("router object or aspath property is undefined");
  }

  if (pageTitleMetadata?.length > 0) {
    const breadcrumbsfromPath = removeMenuFlag
      ? breadcrumbs.filter((item) => item.id != removeMenuIndex)
      : breadcrumbs

    const getRemoveMenuIndex = breadcrumbs.findIndex((item) => item.id == removeMenuIndex);

    breadcrumbs = breadcrumbsfromPath.map((item: any, index: any) => {
      return {
        id: item.id,
        text: item.text.replaceAll('-', ' ').replaceAll('_', ' '),
        href: (productVersionNumber && getRemoveMenuIndex - 1 == index) ? breadcrumbs.filter((item) => item.id == removeMenuIndex)[0].href : item.href,
      }
    })
    return breadcrumbs
  }

  return breadcrumbs
}

export default Breadcrumb
