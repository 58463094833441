import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import React, { useEffect, useState } from 'react'
import styles from '@/components/FooterQuickLinks/footerQuickLinks.module.scss';
import USBDropdown from '@usb-shield/react-dropdown';
import parse from 'html-react-parser'
import '@usb-shield/react-dropdown/dist/library/styles/index.css'
import FooterSocialIcons from '../FooterSocialIcons/FooterSocialIcons';

const FooterQuickLinks = ({ quickLinkData }: any) => {
    let footerTitleDataArray = [] as any
    let footerColsArray = []
   if (quickLinkData?.length) {
    for (let [key, footerDeatilsValue] of Object.entries(quickLinkData) as any) {
        for (let key in footerDeatilsValue as any) {
            if (key.includes('footerCol')) {
                footerColsArray.push(footerDeatilsValue[key])
            }

            let data = footerDeatilsValue[key]
            footerTitleDataArray[key] = data
        }
    }
   }


    let getColumnNumber: any
    let offsetValue: any
    const [columnValue, setColumnValue] = useState(0)

    // Render dynamic columnValue

    useEffect(() => {
        getColumnNumber =
            document.querySelectorAll<HTMLElement>(`.columnNumber`).length * 2
        setColumnValue(getColumnNumber)
    }, [columnValue])

    if (columnValue < 10) {
        let offset: any = (columnValue / 2);
        offsetValue = (offset - 1);
    }
    else {
        offsetValue = '2';
    }

    const quickLinkColsOption = {
        span: null,
        spans: { xlarge: columnValue, large: columnValue, medium: 8, small: 4 },
        offset: { xlarge: offsetValue, large: 2, medium: 0, small: 0 },
        display: 'flex',
        padding: 'zero',
        align: 'start',
        justify: 'stretch',
    }
    const twoColsOption = {
        span: null,
        spans: { xlarge: 2, large: 4, medium: 2, small: 2 },
        offset: { large: 0, medium: 0, small: 0 },
        display: 'flex',
        padding: 'zero',
        align: 'start',
        justify: 'end',
    }

    let coulmnscount = 1
    let customStyle: any;

    const renderFooterQuickLinks = footerColsArray.map(function (
        footerTitle: any,
    ) {
        return (
            <React.Fragment key={'footerTitle'}>
                {footerTitle.map(
                    (footerTitleText: any, ) => (
                        <React.Fragment key={`footer-title-index-${footerTitleText}`}>
                            {(() => {
                                let columnNumber = 'col_' + coulmnscount
                                let customRegionStyle: any;
                                if ((!quickLinkData[0]?.isEnableQuickLinks) && (footerTitleText == "Region")) {
                                    customRegionStyle = styles.quickLinkRegion
                                }

                                if ((footerTitleText == 'Region')) {
                                    if ((!quickLinkData[0]?.isEnableRegionSelector) && (footerTitleText == "Region")) {
                                        coulmnscount--
                                    }
                                    else {
                                        return (
                                            <div
                                                className={`${styles[columnNumber]} columnNumber ${customRegionStyle}`}
                                            >
                                                <h2>{footerTitleText}</h2>
                                                <div data-testid={`regionSelector`}>
                                                    <USBDropdown
                                                        id={styles.region_dropdown_1}
                                                        addClasses={styles.dropdown}
                                                        dropdownType="text"
                                                        label=""
                                                        dropdownPlaceholder={'North America'}
                                                        items={[
                                                            { id: 1, value: 'North America', isSelectable: true },
                                                            { id: 2, value: 'Europe', isSelectable: true },
                                                        ]}
                                                        handleChange={(): void => {/* do nothing */}}
                                                    ></USBDropdown>
                                                </div>
                                            </div>
                                        )
                                    }
                                } else {
                                    if (typeof footerTitleText == 'object') {
                                        coulmnscount++
                                        let customSecurityStyle: any;
                                        if ((!quickLinkData[0]?.isEnableQuickLinks) && (footerTitleText[1] == "Security")) {
                                            customSecurityStyle = styles.quickLinkSecurity
                                        }
                                        if ((!quickLinkData[0]?.isEnableRegionSelector) && (footerTitleText[1] == "Security")) {
                                            customStyle = styles.quickLinkCustomSecurity
                                        }
                                        return (
                                            <div className={`${styles[columnNumber]} columnNumber ${customSecurityStyle} ${customStyle}`}>

                                                {footerTitleText.map(
                                                    (
                                                        footerTitleText: any,
                                                    ) => {
                                                        return (
                                                            <React.Fragment key={`footer-title-${footerTitleText}`}>
                                                                <div>
                                                                    <h2>{footerTitleText}</h2>
                                                                    <ul className={styles.navigation}>
                                                                        {footerTitleDataArray[footerTitleText].listOfLinks &&
                                                                            footerTitleDataArray[
                                                                                footerTitleText
                                                                            ].listOfLinks.map(
                                                                                (
                                                                                    footerTitleData: any,
                                                                                ) => (
                                                                                    <li className={styles.listOfLinks} key={`${footerTitleData.ctaText}-${footerTitleData.ctaLink}`}>
                                                                                        {footerTitleText == 'Quick Links' && footerTitleData.includeExternalLinkIcon ? (
                                                                                            <a href={footerTitleData.ctaLink} className={styles.includeExternalIcon}>
                                                                                                {footerTitleData.ctaText}
                                                                                            </a>
                                                                                        ) : (
                                                                                            <a href={footerTitleData.ctaLink}>
                                                                                                {footerTitleData.ctaText}
                                                                                            </a>
                                                                                        )}

                                                                                    </li>
                                                                                )
                                                                            )}
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        )
                                    } else {
                                        coulmnscount++
                                        let customFooterStyles: any = '';

                                        if ((!quickLinkData[0]?.isEnableQuickLinks) && (footerTitleText == "Reporting")) {
                                            customFooterStyles = styles.quickLinkReporting
                                        }
                                        return (
                                            <div
                                                className={`${styles[columnNumber]} columnNumber ${customFooterStyles}`}
                                            >
                                                <h2>{footerTitleText}</h2>
                                                <ul className={styles.navigation}>
                                                    {footerTitleDataArray[footerTitleText].listOfLinks &&
                                                        footerTitleDataArray[footerTitleText].listOfLinks.map(
                                                            (footerTitleData: any) => (
                                                                <li className={styles.listOfLinks} key={`${footerTitleData.ctaLink}-${footerTitleData.ctaText}`}>
                                                                    <a href={footerTitleData.ctaLink}>
                                                                        {footerTitleData.ctaText}
                                                                    </a>
                                                                </li>
                                                            )
                                                        )}
                                                </ul>
                                            </div>
                                        )
                                    }
                                }
                            })()}
                        </React.Fragment>
                    )
                )}
            </React.Fragment>
        )
    }
    )
    return (
        <section className={styles.footerContainer} data-testid={`footerContainer`}>
            <USBGrid
                gridGap="zero"
                alignItems="stretch"
                columnCount="16"
                justifyContent="stretch"
                display="grid"
                padding="normal"
                addClasses={styles.footer_grid}
            >
                <USBColumn
                    layoutOpts={quickLinkColsOption}
                    addClasses={styles.quickLinksBlock}
                >
                    {renderFooterQuickLinks}

                </USBColumn>
                <USBColumn
                    layoutOpts={twoColsOption}
                    addClasses={styles.supportBlock}
                >
                    <FooterSocialIcons quickLinkData={quickLinkData}></FooterSocialIcons>

                    {footerTitleDataArray['supportDeatils'] &&
                        footerTitleDataArray['supportDeatils'].map(
                            (supportDeatilsData: any) => (
                                <div key={`${supportDeatilsData.title}-supportDetails`} className={styles.supportDetails} data-testid={`supportDetails`}>
                                    <h2>{supportDeatilsData.title}</h2>
                                    <span>{parse(supportDeatilsData.description)}</span>
                                </div>
                            )
                        )}
                </USBColumn>
            </USBGrid>
        </section>
    )
}

export default FooterQuickLinks
