export function getSiteLinkLabel() {
  switch (process.env.SITE_BRAND.toLowerCase()) {
    case 'elan':
      return "Elan Developer Portal homepage"
    
    case 'innovation':
      return "Innovation Developer Portal homepage"
    
    case 'alliance':
      return "Alliance Developer Portal homepage"
  
    default:
      return "U.S. Bank Developer Portal homepage"
  }
}